<template>
    <div v-if="authenticated.role?.accessModel?.projects > 0" class="w-full flex flex-col flex-grow items-center text-xs">
<!-- Heading -->
        <div class="page-header">
            <div class="text-left truncate px-1 text-base">{{project.name}}</div>
        </div>
<!-- Top Menu -->
        <div class="w-full flex flex-col items-center justify-center p-2 truncate">
            <div class="w-full flex flex-row items-center justify-start sm:justify-center panel max-w-5xl flex-wrap p-0.5">
                <div v-for="(menuButton,index) in menuButtons" :key="index" class="flex flex-col justify-center">
                    <div v-if="menuButton.auth" class="pt-1 px-1">
                    <Popper hover arrow placement="top" :content="menuButton.popper">
                        <button class="bn-icon-small" :class="menuButton.buttonClass ? menuButton.buttonClass :'bn-solid'" @click="openModal(menuButton.modal,menuButton.popper)" :disabled="Object.keys(project).length === 0 || isWorking">
                            <component :is="menuButton.icon" />
                        </button>
                    </Popper>
                    </div>
                </div>
                <!--<div v-if="allowEdit && authenticated?.organization?.orgType && authenticated.organization.orgType.includes('primary')" class="flex flex-col justify-center">
                    <div class="pt-1 px-1">
                        <DeleteConfirm v-if="project.isOpen" :size="'small'" :iconComponent="ClipboardCheckIcon" @confirmedDelete="updateProject('isOpen',false,project.isOpen)"
                        :popperContent="'Close Project'" :disableButton="Object.keys(project).length === 0 || isWorking"
                        />
                        <div v-else>
                            <Popper hover arrow placement="top" :content="'Re-open'">
                                <button class="bn-icon-small bn-solid-green" @click="updateProject('isOpen',true,project.isOpen)" :disabled="Object.keys(project).length === 0 || isWorking">
                                    <ClipboardCheckIcon />
                                </button>
                            </Popper>
                        </div>
                    </div>
                </div>-->
                <router-link :to="'/projects'" replace class="flex flex-col justify-center" :class="isWorking ? 'opacity-40' : null" :disabled="isWorking">
                    <div class="pt-1 px-1">
                        <Popper hover arrow placement="top" :content="'Projects'">
                            <button class="bn-icon-small bn-solid-sky">
                                <ReplyIcon />
                            </button>
                        </Popper>
                    </div>
                </router-link >
            </div>
        </div>
<!-- Loading and Errors -->
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
<!-- Project -->
        <div v-if="Object.keys(project).length > 0" class="w-full flex flex-col flex-grow items-center p-1 max-w-7xl">
            <div class="w-full flex flex-col flex-grow flex-auto h-0 items-center overflow-y-auto p-1">
                <div class="w-full flex flex-grow flex-col space-y-1 items-center overflow-y-auto px-2">
        <!-- Credit Hold Message -->
                    <div v-if="project?.projectClientId?.activeHold && Object.keys(project?.projectClientId?.activeHold).length > 0 && authenticated?.organization?.orgType.includes('primary') && authenticated.role?.accessModel?.projects > 3" class="w-full sticky top-0 z-70">
                        <div class="w-full p-1 bg-red-700 text-neutral-50 rounded-md bg-opacity-90 flex flex-row items-center px-2 flex-wrap md:justify-center text-xs">
                            <div class="text-left font-semibold pr-1">Credit Hold:</div>
                            <div v-if="project?.projectClientId?.activeHold?.startDate" class="text-left pr-1">{{format(new Date(project.projectClientId.activeHold.startDate), ' MMM dd, yyyy')}}:</div>
                            <div v-if="project?.projectClientId?.activeHold?.holdReason" class="text-left whitespace-pre-wrap truncate">{{project.projectClientId.activeHold.holdReason}}</div>
                            <div v-if="project?.projectClientId?.activeHold?.holdBy" class="text-left">{{` - ${project.projectClientId.activeHold.holdBy}`}}</div>
                        </div>
                    </div>
        <!-- Close Invoicing -->
                    <div v-if="primaryOnly && allowSecure" class="w-full flex flex-col p-1 panel">
                        <div class="w-full flex items-center justify-center truncate p-1">
                            <div v-if="!project.isOpen" class="px-2 whitespace-normal text-center font-semibold text-red-600">
                                Project Closed
                            </div>
                            <div v-else-if="project.closeInvoicing > 1" class="px-2">
                                <button class="px-1 py-0.5 bn-solid-red" @click="closeProject()">
                                    <ClipboardCheckIcon />
                                    <div>Close Project</div>
                                </button>
                            </div>
                            <div v-else-if="project.closeInvoicing < 2" class="px-2">
                                <div v-if="!showInvoiceCloseConfirm" class="px-2">
                                    <button class="px-1 py-0.5 bn-solid-green" @click="showInvoiceCloseConfirm = true" :disabled="!Array.isArray(project?.invoices) || project.closeInvoicing > 0 || !canCloseInvoicing">
                                        <div>Close Invoicing</div>
                                    </button>
                                </div>
                                <ConfirmButton v-else-if="showInvoiceCloseConfirm" @response="($event)=>$event ? requestCloseInvoicing() : showInvoiceCloseConfirm = false" />
                            </div>
                        </div>
                        <div v-if="project.closeInvoicing === 2 && project.invoicingReasonCode" class="w-full p-1">
                            <div class="w-full text-center whitespace-normal truncate p-1 border-t">
                                <span class="font-semibold">Unrealized Revenue:</span> {{ project.invoicingReasonCode }}
                            </div>
                        </div>
                    </div>
        <!-- Project Information -->
                    <div class="w-full flex flex-col space-y-3 panel p-2 items-start">
                        <div v-if="project.projectClientId?.logoId  && project.projectClientId?.logoId != ''" class="flex items-center justify-items-start px-2">
                            <img :src="`https://drive.google.com/thumbnail?authuser=0&size=h100&id=${project.projectClientId.logoId}`" onerror="this.style.display='none';this.nextElementSibling.style.display='block';" class="object-contain align-middle border-none p-1" :style="'max-width:200px;max-height:200px'" />
                            <div class="text-left whitespace-normal truncate p-1 font-semibold text-base" style="display:none;">{{project.projectClientId?.name}}</div>
                        </div>
                        <div v-else class="text-left truncate sm:text-sm">{{project.projectClientId?.name}}</div>
                        <div v-if="allowEdit && false" class="w-full flex px-1">
                            <Popper hover arrow placement="top" :content="'Change Client'">
                                <button class="bn-solid px-2 py-0.5" :disabled="isWorking">
                                    <SwitchVerticalIcon />
                                    <OfficeBuildingIcon />
                                </button>
                            </Popper>
                        </div>
                        <div class="w-full flex flex-row space-x-2 items-center max-w-3xl">
                            <input v-model="project.name" type="text" class="w-full max-w-xl" placeholder="project name" :disabled="!allowProjectDetailsEdit  || isWorking"
                            @focus="prevValue = project.name" @blur="project.name !== prevValue ? updateProject('name',project.name,prevValue) : null"
                            >
                        </div>
                        <div class="w-full flex flex-row items-center justify-start sm:justify-between max-w-3xl truncate flex-wrap p-1">
                            <div class="w-full flex flex-col space-y-1 justify-start max-w-xs p-1 sm:p-0">
                                <input v-model="project.clientProjectNumber" type="text" class="w-48" placeholder="client project #" :disabled="!allowProjectDetailsEdit  || isWorking"
                                @focus="prevValue = project.clientProjectNumber" @blur="project.clientProjectNumber !== prevValue ? updateProject('clientProjectNumber',project.clientProjectNumber,prevValue) : null"
                                >
                                <div class="text-left px-2">client project number</div>
                            </div>
                            <div class="w-full flex flex-col space-y-1 justify-start max-w-xs p-1 sm:p-0">
                                <input v-model="project.companyProjectNumber" type="text" class="w-48" placeholder="client project #" :disabled="!allowProjectDetailsEdit || isWorking"
                                @focus="prevValue = project.companyProjectNumber" @blur="project.companyProjectNumber !== prevValue ? updateProject('companyProjectNumber',project.companyProjectNumber,prevValue) : null"
                                >
                                <div class="text-left px-2">client project number</div>
                            </div>
                        </div>
                    </div>
        <!-- External Tracker Link -->
                    <div class="w-full flex flex-col space-y-1 panel p-1 items-start">
                        <div class="w-full text-left p-1 truncate font-semibold border-b">External Links</div>
                        <div class="w-full flex flex-col px-2">
                            <div v-if="allowSecure" class="p-1 flex items-center">
                                <ButtonWithConfirm :iconComponent="ShieldCheckIcon" :popperContent="project.secureCode ? 'Regenerate External Code' : 'Generate External Code'" :disabled="isWorking" @confirmed="generateSecureCode()" />
                            </div>
                            <div class="w-full text-left whitespace-normal truncate">Equipment Tracker</div>
                            <div class="w-full flex flex-row items-center">
                                <div class="p-1 flex items-center">
                                    <Popper hover arrow placement="top" :content="'Copy to Clipboard'">
                                        <button v-if="project.secureCode" class="bn-icon-only" @click="copyToClipboard(secureLink)">
                                            <ClipboardCopyIcon />
                                        </button>
                                    </Popper>
                                </div>
                                <div class="p-1 text-left overflow-hidden break-words">{{secureLink}}</div>
                            </div>
                            <div class="w-full text-left whitespace-normal truncate">Room Tracker</div>
                           <div class="w-full flex flex-row items-center">
                                <div class="p-1 flex items-center">
                                    <Popper hover arrow placement="top" :content="'Copy to Clipboard'">
                                        <button v-if="project.secureCode" class="bn-icon-only" @click="copyToClipboard(secureLinkRoomTracker)">
                                            <ClipboardCopyIcon />
                                        </button>
                                    </Popper>
                                </div>
                                <div class="p-1 text-left overflow-hidden break-words">{{secureLinkRoomTracker}}</div>
                            </div>
                        </div>
                    </div>
        <!-- Project Address -->
                    <div class="w-full flex flex-col space-y-3 panel p-2 items-start flex-shrink-0">
                        <div class="w-full text-left p-1 truncate font-semibold border-b">Address</div>
                        <div class="w-full max-w-2xl">
                            <editableAddress 
                            :disabled="isWorking || !allowProjectDetailsEdit "
                            :allowEdit="allowEdit"
                            :canBeEmpty="false"
                            :currentAddress="project.projectAddress"
                            @addressChange="updateProject('projectAddress',$event,project.projectAddress)"
                            />
                        </div>
                    </div>
        <!-- Project Management -->
                    <div class="w-full flex flex-col space-y-3 panel p-2 items-start">
                        <div class="w-full text-left p-1 truncate font-semibold border-b">Project Management</div>
                        <div class="w-full flex flex-row items-center justify-start max-w-5xl flex-wrap md:flex-nowrap">
                            <div class="w-full flex flex-col space-y-2 items-center justify-center self-stretch p-1">
                                <div v-if="allowProjectDetailsEdit" class="w-full max-w-md flex flex-row space-x-2 items-center justify-end px-1 truncate">
                                    <clientManagerSelect v-if="showChangeClientManagerOption" :clientId="project.projectClientId?._id" :currentManagerId="project.clientMgrId?._id" :isDisabled="isWorking"
                                    @managerSelected="updateProject('clientMgrId',$event,project.clientMgrId?._id || project.clientMgrId || null);showChangeClientManagerOption = false" 
                                    />
                                    <div>
                                        <Popper hover arrow placement="top" :content="'Change client mgr.'">
                                            <button class="bn-icon-small bn-solid" 
                                            @click="showChangeClientManagerOption = !showChangeClientManagerOption" 
                                            :disabled="isWorking"
                                            >
                                                <XIcon v-if="showChangeClientManagerOption" />
                                                <PencilAltIcon v-else />
                                            </button>
                                        </Popper>
                                    </div>
                                </div>
                                <div v-if="project.clientMgrId && Object.keys(project.clientMgrId).length > 0" class="w-full max-w-md">
                                    <userDisplayCard  :userInfo="project.clientMgrId" />
                                </div>
                                <div v-else class="w-full p-1 max-w-md flex flex-grow items-center justify-center truncate font-semibold">Not Assigned</div>
                            </div>
                            <div class="w-full flex flex-col space-y-2 items-center justify-center self-stretch p-1">
                                <div v-if="allowProjectDetailsEdit" class="w-full max-w-md flex flex-row space-x-1 items-center justify-end px-1 truncate">
                                   <companyManagerSelect v-if="showChangeCompanyManagerOption" :currentManagerId="project.companyMgrId?._id" :isDisabled="isWorking"
                                    @managerSelected="updateProject('companyMgrId',$event,project.companyMgrId?._id || project.companyMgrId || null);showChangeCompanyManagerOption = false" 
                                    />
                                    <div>
                                        <Popper hover arrow placement="top" :content="'Change company mgr.'">
                                            <button class="bn-icon-small bn-solid" 
                                            @click="showChangeCompanyManagerOption = !showChangeCompanyManagerOption" 
                                            :disabled="isWorking || (authenticated?.organization?.orgType && !authenticated.organization.orgType.includes('primary'))"
                                            >
                                                <XIcon v-if="showChangeCompanyManagerOption" />
                                                <PencilAltIcon v-else/>
                                            </button>
                                        </Popper>
                                    </div>
                                </div>
                                <div v-if="project.companyMgrId && Object.keys(project.companyMgrId).length > 0" class="w-full max-w-md">
                                    <userDisplayCard  :userInfo="project.companyMgrId" />
                                </div>
                                <div v-else class="w-full p-1 max-w-md flex flex-grow items-center justify-center truncate font-semibold">Not Assigned</div>
                            </div>
                        </div>
                    </div>
        <!-- Project Dates -->
                    <div class="w-full flex flex-col space-y-3 panel p-2 items-center ">
                        <div class="w-full text-left p-1 truncate font-semibold border-b">Dates</div>
                        <div class="w-full flex flex-row items-center justify-between max-w-4xl flex-wrap">
                            <div class="w-36 flex-shrink-0 flex flex-col space-y-1 justify-start p-1">
                                <input v-model="project.installStartDate" type="date" class="w-36" :disabled="!allowProjectDetailsEdit || isWorking" 
                                @click="prevValue = project.installStartDate "
                                @change="updateDate('installStartDate',$event.target.value,prevValue)"
                                @keydown.prevent="null"
                                />
                                <div class="text-left px-2">start</div>
                            </div>
                            <div class="w-36 flex-shrink-0 flex flex-col space-y-1 justify-start max-w-xs p-1">
                                <input v-model="project.installEndDate" type="date" class="w-36" :disabled="!allowProjectDetailsEdit || isWorking" @keydown.prevent="null"
                                @click="prevValue = project.installEndDate "
                                @change="updateDate('installEndDate',$event.target.value,prevValue)"
                                />
                                <div class="text-left px-2">end</div>
                            </div>
                            <div class="w-36 flex-shrink-0 flex flex-col space-y-1 justify-start max-w-xs p-1">
                                <input v-model="project.poReceivedDate" type="date" class="w-36" :disabled="!allowProjectDetailsEdit || isWorking" @keydown.prevent="null"
                                @click="prevValue = project.poReceivedDate "
                                @change="updateDate('poReceivedDate',$event.target.value,prevValue)"
                                />
                                <div class="text-left px-2">PO received</div>
                            </div>
                        </div>
                    </div>
        <!-- Project Description -->
                    <div class="w-full flex flex-col space-y-3 panel p-2 items-start md:items-center ">
                        <div class="w-full text-left p-1 truncate font-semibold border-b">Description</div>
                        <div class="w-full flex flex-col max-w-3xl truncate p-1 space-y-0.5">
                            <textarea class="whitespace-pre h-32" v-model="project.projectDescription" :disabled="isWorking || !allowProjectDetailsEdit" 
                            @focus="prevValue = project.projectDescription" 
                            @blur="project.projectDescription !== prevValue ? updateProject('projectDescription',project.projectDescription,prevValue) : null"
                            />
                            <div class="w-full text-left px-2" :class="project.projectDescription.length > 1000 ? 'text-red-500' : null">{{`${project.projectDescription.length} chars`}}</div>
                        </div>
                    </div>
        <!-- Prevailing Wage -->
                    <div v-if="allowSecure" class="w-full flex flex-col space-y-3 panel p-2 items-start md:items-center ">
                        <div class="w-full text-left p-1 truncate font-semibold border-b">Prevailing Wage Hourly Rate</div>
                        <div class="w-full flex flex-col">
                            <div class="w-full flex flex-row space-x-12 items-center max-w-xs">
                                <toggle :initialButtonState="project?.isPrevailingWage" @toggleState="updateProject('isPrevailingWage',$event,!project.isPrevailingWage);!project.isPrevailingWage ? updateProject('isPrevailingWage',$event,project.isPrevailingWage): null " />
                                <div v-if="project.isPrevailingWage" class="flex flex-row items-center h-8">
                                    <div class="w-6 h-6 flex items-center justify-center rounded-l-md font-semibold text-xs">$</div>
                                    <input v-model="project.prevailingWage" type="number" min="0" max="300" step=".10" class="w-16" 
                                    @focus="prevValue = project.prevailingWage" @blur="updateProject('prevailingWage',project.prevailingWage,prevValue)"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="flex flex-col py-2">
        <NoAccess />
    </div>
    <Modal v-if="showModal" @closeModal="showModal = null" :title="`${modalTitle} - ${project.companyProjectNumber ? project.companyProjectNumber: ''} ${project?.name}`" :showFull="showModal === 'equipment'">
        <template v-slot:content>
            <ProjectSOW v-if="showModal === 'sow'" :project="project" :allowEdit="allowEdit" @triggerProjectUpdate="getProject('silent')" />
            <Notes v-if="showModal === 'notes'" :projectId="project._id" :allowEdit="allowEdit" :notes="project.notes || []" @triggerProjectUpdate="getProject('silent')" />
            <Reports v-if="showModal === 'reports'" :project="project" :allowEdit="primaryOnly" />
            <Services v-if="showModal === 'services'" :project="project" @triggerProjectUpdate="getProject('silent')" :allowEdit="allowEdit && allowSecure" />
            <EquipmentTracker v-if="showModal === 'equipment'" :project="project" />
            <Rooms v-if="showModal === 'rooms'" :project="project" :allowEdit="allowEdit" />
            <Shipping v-if="showModal === 'shipping'" :project="project" :allowEdit="allowEdit" />
            <ProjectTimeUsed v-if="showModal==='time'"  :project="project" :services="[...project.services]" :allowEdit="allowSecure"/>
            <ProjectStaffing v-if="showModal=== 'staffing'" :project="project" />
            <ProjectPhotos v-if="false" :project="project" :allowEdit="primaryOnly" />
            <Files v-if="showModal === 'photos'" :model="'projects'" :entity="project" :route="'photos'"  :allowEdit="primaryOnly" />
            <Files v-if="showModal === 'documents'" :model="'projects'" :entity="project" :route="'documents'"  @triggerProjectUpdate="getProject('silent')" :allowEdit="allowEdit" />
            <Files v-if="showModal === 'financial'" :model="'projects'" :entity="project" :route="'documents'" secure  @triggerProjectUpdate="getProject('silent')" :allowEdit="allowEdit" />
            <InvoiceByProject v-if="showModal === 'invoices'" :clientId="project.projectClientId?._id || project.projectClientId " :projectId="project._id" :invoices="project.invoices" :sales="project.sales || 0" :allowInvoiceActivity="project.isOpen && project.closeInvoicing === 0" 
                @triggerProjectUpdate="getProject('silent')"
            />
            <SiteContacts v-if="showModal === 'siteContacts'" :project="project"  :allowEdit="allowEdit" @triggerProjectUpdate="getProject('silent')" />
            <Followers v-if="showModal === 'following'" :project="project" :allowEdit="allowEdit" @triggerProjectUpdate="getProject('silent')" />
        </template>
    </Modal>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import {
    SwitchVerticalIcon,
    OfficeBuildingIcon,
    PencilAltIcon,
    XIcon,
    ReplyIcon,
    DocumentTextIcon,
    CashIcon,
    ClipboardCheckIcon
    } from "@heroicons/vue/outline"
import {
    OfficeBuildingIcon as OfficeBuildingIconSolid,
    DocumentReportIcon,
    CogIcon,
    DocumentTextIcon as DocumentTextIconSolid,
    ServerIcon,
    TruckIcon,
    PhotographIcon,
    DocumentDuplicateIcon,
    CurrencyDollarIcon,
    UserGroupIcon,
    DocumentIcon,
    ClipboardCopyIcon,
    ShieldCheckIcon
    } from "@heroicons/vue/solid"
import { useRoute } from 'vue-router'
import api from '@/api'
import {format,isValid, parseISO} from 'date-fns'
import {copyToClipboard} from '@/shared'
import editableAddress from "@/components/ui/editableAddress.vue"
import userDisplayCard from '@/components/cards/userDisplayCard.vue'
import clientManagerSelect from './projectManagement/clientManagerSelect.vue'
import companyManagerSelect from './projectManagement/companyManagerSelect.vue'
import toggle from '@/components/ui/toggle.vue'
import MdDoorFrontIcon from "@/components/customIcons/MdDoorFrontIcon.vue"
import PunchClock from '../customIcons/PunchClock.vue'
import MdManageAccountsIcon from '@/components/customIcons/MdManageAccountsIcon.vue'
import InvoiceByProject from "@/components/projects/invoices/InvoiceByProject.vue"
import Notes from "@/components/projects/notes/Notes.vue"
//import SOW from "@/components/projects/sow/SOW.vue"
import EquipmentTracker from "@/components/projects/equipment/EquipmentTracker.vue"
import SiteContacts from "@/components/projects/siteContacts/SiteContacts.vue"
import Followers from "@/components/projects/followers/Followers.vue"
import ProjectPhotos from "@/components/projects/fileManagement/ProjectPhotos.vue"
//import ProjectFiles from "@/components/projects/fileManagement/ProjectFiles.vue"
import Services from "@/components/projects/services/Services.vue"
//import DailyReports from "@/components/projects/dailyReports/DailyReports.vue"
import Reports from "@/components/projects/reports/Reports.vue"
import Rooms from "@/components/projects/rooms/Rooms.vue"
import Shipping from "@/components/projects/shipping/Shipping.vue"
import ProjectTimeUsed from "@/components/projects/projectTime/ProjectTimeUsed.vue"
import ButtonWithConfirm from '../ui/ButtonWithConfirm.vue'
import Files from '@/components/ui/Files.vue'
import ProjectSOW from './sow/ProjectSOW.vue'
import ProjectStaffing from '@/components/projects/staff/ProjectStaffing.vue'



export default {
    components:{
        editableAddress,
        userDisplayCard,
        clientManagerSelect,
        companyManagerSelect,
        toggle,
        InvoiceByProject,
        Notes,
        //SOW,
        EquipmentTracker,
        SiteContacts,
        Followers,
        ProjectPhotos,
        Files,
        ProjectSOW,
        Services,
        //DailyReports,
        Reports,
        Rooms,
        Shipping,
        ProjectTimeUsed,
        ProjectStaffing,
        ButtonWithConfirm,
        SwitchVerticalIcon,
        OfficeBuildingIcon,
        PencilAltIcon,
        XIcon,
        ReplyIcon,
        DocumentTextIcon,
        DocumentReportIcon,
        OfficeBuildingIconSolid,
        CogIcon,
        ServerIcon,
        MdDoorFrontIcon,
        TruckIcon,
        PhotographIcon,
        DocumentDuplicateIcon,
        CurrencyDollarIcon,
        CashIcon,
        DocumentIcon,
        ClipboardCheckIcon,
        PunchClock,
        ClipboardCopyIcon,
        MdManageAccountsIcon
        },
    setup() {
        const global = inject('global')
        const {authenticated,sendChangeEvent} = global

        const primaryOnly = ref(authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('primary'))
        const allowProjectDetailsEdit = ref(authenticated.value.role?.accessModel?.projects >=3)
        const allowEdit = ref(authenticated.value.role?.accessModel?.projects >=2)
        const allowSecure = ref(authenticated.value.organization?.orgType && 
        authenticated.value.organization?.orgType.includes('primary') && 
        authenticated.value.role?.accessModel?.projects === 4)

        const route = useRoute()
        const projectId = route.params.projectId

        const responseError = ref(null)
        const isWorking = ref(false)

        const project = ref({})
        const clientOrgOptions = ref([])
        const companyManagers = ref([])
        const serviceCatalog = ref([])
        const showChangeClientManagerOption = ref(false)
        const showChangeCompanyManagerOption = ref(false)
        const showModal = ref(null)
        const modalTitle = ref(null)

        const menuButtons = [
            {icon:DocumentTextIconSolid,popper:'SOW',modal:'sow',auth:primaryOnly.value},
            {icon:DocumentIcon,popper:'Notes',modal:'notes',auth:primaryOnly.value && allowEdit.value},
            {icon:DocumentReportIcon,popper:'Daily Reports',modal:'reports',auth:true},
            {icon:CogIcon,popper:'Services',modal:'services',auth:primaryOnly.value},
            {icon:ServerIcon,popper:'Equipment',modal:'equipment',auth:true},
            {icon:MdDoorFrontIcon,popper:'Rooms',modal:'rooms',auth:true},
            {icon:TruckIcon,popper:'Shipping',modal:'shipping',auth:true},
            {icon:PunchClock,popper:'Hours',modal:'time',auth:primaryOnly.value && allowSecure.value},
            {icon:MdManageAccountsIcon,popper:'Techical Staff',modal:'staffing',auth:true},
            {icon:PhotographIcon,popper:'Photos',modal:'photos',auth:true},
            {icon:DocumentDuplicateIcon,popper:'Documents',modal:'documents',auth:true},
            {icon:CurrencyDollarIcon,popper:'Financial Documents',modal:'financial',auth:primaryOnly.value && allowSecure.value},
            {icon:CashIcon,popper:'Invoices',modal:'invoices',buttonClass:'bn-solid-green',auth:primaryOnly.value && allowSecure.value},
            {icon:OfficeBuildingIconSolid,popper:'Site Contacts',modal:'siteContacts',auth:true},
            {icon:UserGroupIcon,popper:'Following',modal:'following',auth:true}
        ]

        const showInvoiceCloseConfirm = ref(false)

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getProject()
            allowEdit.value ? getNewProjectRequirements() : null
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const secureLink = computed(()=>{
            return project.value.secureCode ? `${window.location.protocol}//${window.location.host}/external/${project.value._id}/${project.value.secureCode}` : 'Not Available'
        })

        const secureLinkRoomTracker = computed(()=>{
            return project.value.secureCode ? `${window.location.protocol}//${window.location.host}/external/roomTracker/${project.value._id}/${project.value.secureCode}` : 'Not Available'
        })

        const canCloseInvoicing = computed(()=>{
            return project.value.isOpen && project.value.closeInvoicing === 0 && Array.isArray(project.value.invoices) && (project.value.invoices.length === 0 || project.value.invoices.filter(invoice=>!invoice.requestDate).length === 0)
        })

        const getProject = async (type)=>{
            responseError.value = null
            type !== 'silent' ? isWorking.value = true : null
            await api
            .get("projects/" + projectId)
            .then((res)=>{
                project.value = res.data?.data
                let title = `${res.data?.data?.companyProjectNumber} - ${res.data?.data?.name}`;
                document.title = title.length > 60 ? title.substring(0, 60) : title;
                formatProject()
            })
            .catch((err)=>{
                responseError.value.project = err.response?.data?.error || err.message  || "Error Getting Project."
            })
            .finally(()=>{
                isWorking.value = false
            })
        }

        const getNewProjectRequirements = async ()=>{
            await api
            .get("projects/newProjectRequirements")
            .then((res)=>{
                if(res.data?.data) {
                    clientOrgOptions.value = res.data?.data?.clients && Array.isArray(res.data.data.clients) ? res.data.data.clients : []
                    serviceCatalog.value = res.data?.data?.serviceCatalog && Array.isArray(res.data.data.serviceCatalog) ? res.data.data.serviceCatalog : []
                }

            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        const updateProject = async (key,value,prevValue)=>{
            isWorking.value = true
            responseError.value = null
            let body = {[key]:value}
            await api
            .put("projects/" + project.value._id,body)
            .then((res)=>{
                project.value = res.data?.data
                formatProject()
                sendChangeEvent('project',projectId,'',{projectId:projectId})
            })
            .catch((err)=>{
                project.value[key] = prevValue
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
            })
        }

        const closeProject = async ()=>{
            isWorking.value = true
            responseError.value = null
            await api
            .put("projects/closeProject/" + project.value._id,{})
            .then((res)=>{
                project.value = res.data?.data
                formatProject()
                sendChangeEvent('project',projectId,'',{projectId:projectId})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
            })
        }

        const generateSecureCode = async ()=>{
            isWorking.value = true
            responseError.value = null
            await api
            .get(`projects/secureCode/${project.value._id}`)
            .then((res)=>{
                project.value.secureCode = res.data?.data
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
            })
        }

        const requestCloseInvoicing =  async ()=>{
            isWorking.value = true
            responseError.value = null
            await api
            .get(`projects/requestInvoiceClose/${project.value._id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    project.value = res.data?.data
                    formatProject()
                    sendChangeEvent('project',projectId,'',{projectId:projectId,isCloseInvoicing:true})
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                showInvoiceCloseConfirm.value = false
            })
        }

        const formatProject = () => {
            project.value.installStartDate = formatLocalDate(project.value.installStartDate)
            project.value.installEndDate = formatLocalDate(project.value.installEndDate)
            project.value.poReceivedDate = formatLocalDate(project.value.poReceivedDate)
        };

        const updateDate = (key, val, prevValue) => {
            if (isValid(parseISO(val))) {
                let selectedDate = new Date(val)
                let convertedDate =  new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() *60000)
                updateProject(`${key}`,convertedDate, prevValue);
            } else {
                project.value[key] = prevValue;
            }
        }
    

        const formatLocalDate = (val)=>{
            if (val && isValid(new Date(val))) {
                const newDate = parseISO(val)
                const month = String(newDate.getUTCMonth() + 1).padStart(2, '0');
                const day = String(newDate.getUTCDate()).padStart(2, '0');
                return `${newDate.getUTCFullYear()}-${month}-${day}`;
            } else {
                return null
            }
        }

        const openModal = (component,title)=>{
            showModal.value = component,
            modalTitle.value = title
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'project':
                    case 'invoice':
                    case 'note':
                    case 'sow':
                    case 'following':
                    case 'siteContact':
                    //organization
                        e.detail.data?.projectId === project.value._id
                        ? getProject('silent')
                        : null
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            projectId,
            authenticated,
            allowEdit,
            allowSecure,
            responseError,
            isWorking,
            format,
            menuButtons,
            project,
            updateProject,
            companyManagers,
            showChangeClientManagerOption,
            showChangeCompanyManagerOption,
            updateDate,
            showModal,
            openModal,
            modalTitle,
            getProject,
            primaryOnly,
            ClipboardCheckIcon,
            secureLink,
            copyToClipboard,
            ShieldCheckIcon,
            generateSecureCode,
            secureLinkRoomTracker,
            allowProjectDetailsEdit,
            showInvoiceCloseConfirm,
            requestCloseInvoicing,
            canCloseInvoicing,
            closeProject
        }
    }

}
</script>

<style>

</style>