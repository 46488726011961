<template>
    <div class="w-full flex flex-col flex-grow items-center space-y-2 max-w-3xl lg:max-w-4xl">
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
<!-- New Room, Add Equipment and Room Tools Buttons -->
        <div class="w-full flex flex-col space-y-1 items-center">
            <div class="w-full flex items-center truncate">
                <div v-if="allowEdit" class="p-1">
                    <button class="px-2 py-1 bn-solid-green" @click="toggleNewRoomDialog()" :disabled="isWorking">
                        <XIcon v-if="showNewRoomDialog" />
                        <PlusIcon v-else />
                        <div>Room</div>
                    </button>
                </div>
                <div class="p-1">
                    <button class="px-2 py-1 bn-solid-green" @click="showEquipmentModal = true" :disabled="isWorking || isGettingEquipment || (equipment && equipment.length === 0)">
                        <CogIcon v-if="isGettingEquipment" class="animate-spin-slow" />
                        <PlusIcon v-else-if="allowEdit" />
                        <ExternalLinkIcon v-else />
                        <div>{{!isGettingEquipment && (equipment && equipment.length === 0) ? 'No Equip.' : 'Equip.'}}</div>
                    </button>
                </div>
                <div v-if="allowEdit && rooms && rooms.length > 0" class="flex flex-grow p-1 items-center truncate justify-end">
                    <div class="p-1">
                        <Popper hover arrow placement="top" :content="`Room Names`">
                            <button class="bn-solid px-2 py-0.5" @click="toggleRoomNamesDialog()">
                                <MdDoorFrontIcon />
                                <XIcon v-if="showRoomNamesDialog" />
                                <MenuAlt2Icon v-else />
                            </button>
                        </Popper>
                    </div>
                </div>
            </div>
    <!-- New Room Dialog -->
            <div v-if="showNewRoomDialog && allowEdit" class="w-full flex flex-col items-center py-2 border-b">
                <div class="w-full flex flex-col max-w-xl panel px-2">
                    <div class="w-full truncate px-2">
                        <div class="w-full text-left px-1 py-2 truncate whitespace-normal border-b font-semibold">New Room(s)</div>
                    </div>
                    <div class="w-full flex flex-row space-x-1 items-center p-1">
                        <div class="flex flex-col w-full space-y-2 p-1">
                            <div class="w-full flex flex-row items-center space-x-2 flex-nowrap">
                                <input v-model.trim="newRoom.roomType" type="text" list="roomTypeOptions" class="w-full" maxlength="25" placeholder="room type" :disabled="isWorking">
                                <datalist class="w-full" id="roomTypeOptions" autocomplete="true">
                                    <option v-for="(option,index) in newRoomTypeOptions" :key="index" :value="option" class="w-full trunctate">{{option}}</option>
                                </datalist>
                                <input v-model="newRoomNumberOf" type="number" class="w-12 flex-shrink-0 text-center" placeholder="#" min="0" max="100" :disabled="isWorking"
                                @input="newRoomNumberOf = formatNumberInput($event.target.value,1,100)"
                                >
                            </div>
                            <div class="w-full max-w-md">
                                <MultiSelect :placeHolder="'AV Tasks'" :options="roomAvTaskOptions" :keyName="'avTask'" :defaultSelections="newRoom.avReadiness" :disabled="isWorking" @selectionUpdated="newRoom.avReadiness = $event" />
                            </div>
                        </div>
                        <button class="bn-icon bn-solid-green" @click="saveNewRoom" :disabled="isWorking || !newRoom.roomType">
                            <SaveIcon />
                        </button>
                    </div>
                </div>
            </div>
    <!-- Room Names Dialog -->
            <div v-if="showRoomNamesDialog && allowEdit" class="w-full panel p-2 max-w-xl">
                <div class="w-full flex flex-col space-y-1">
                    <div class="w-full text-left p-1 truncate whitespace-normal border-b font-semibold">Room Names</div>
                    <div class="w-full flex flex-row items-center truncate">
                        <div class="w-44 sm:w-full p-1">
                            <select v-model="renameRooms.type" class="w-full">
                                <option :value="null" hidden>Room type</option>
                                <option v-for="(option,index) in newRoomTypeOptions" :key="index" :value="option" class="w-full trunctate">{{option}}</option>
                            </select>
                        </div>
                        <div class="p-1 hidden sm:flex items-center">
                            <ArrowSmRightIcon class="w-4 h-4" />
                        </div>
                        <div class="w-44 sm:w-full p-1">
                            <input v-model.trim="renameRooms.to" class="w-full" placeholder="new name" maxlength="25" :disabled="isWorking || !renameRooms.type" />
                        </div>
                        <div class="flex flex-grow items-center justify-end p-1">
                            <button class="bn-icon-small bn-solid-green" @click="renameRoomsInProject()"
                            :disabled="isWorking || !renameRooms.type || !renameRooms.to"
                            >
                                <SaveIcon />
                            </button>
                        </div>
                    </div>
                    <div class="text-left truncate px-2 opacity-60 italic">Rename all rooms of type</div>
                </div>
                <div class="w-full flex flex-col space-y-1">
                    <div class="w-full flex flex-row items-center truncate">
                        <div class="w-44 sm:w-full p-1">
                            <select v-model="nameUnnamedRooms.type" class="w-full">
                                <option :value="null" hidden>Room type</option>
                                <option v-for="(option,index) in newRoomTypeOptions" :key="index" :value="option" class="w-full trunctate">{{option}}</option>
                            </select>
                        </div>
                        <div class="p-1 hidden sm:flex items-center">
                            <ArrowSmRightIcon class="w-4 h-4" />
                        </div>
                        <div class="w-44 sm:w-full p-1">
                            <input v-model.trim="nameUnnamedRooms.to" class="w-full" placeholder="starting text" :disabled="isWorking || !nameUnnamedRooms.type" maxlength="45" />
                        </div>
                        <div class="flex flex-grow items-center justify-end p-1">
                            <button class="bn-icon-small bn-solid-green" @click="nameUnnamedRoomsInProject()"
                            :disabled="isWorking || !nameUnnamedRooms.type || !nameUnnamedRooms.to"
                            >
                                <SaveIcon />
                            </button>
                        </div>
                    </div>
                    <div class="text-left whitespace-normal truncate px-2 opacity-60 italic">Name all unnamed rooms of specified type.</div>
                </div>
            </div>
        </div>
<!-- Room Filter -->
        <div v-if="rooms && rooms.length > 0" class="w-full p-1 flex items-center justify-start sm:justify-between lg:justify-center panel truncate flex-wrap">
            <div class="w-full max-w-xs p-1">
                <input v-model="roomFilter" type="search" placeholder="filter rooms" class="w-full">
            </div>
            <div class="w-48 p-1">
                <select v-model="roomPhaseFilter" class="w-full" :disabled="roomFilterPhaseOptions.length === 0">
                    <option selected :value="null">phase</option>
                    <option v-for="phase in roomFilterPhaseOptions" :key="phase">{{phase}}</option>
                </select>
            </div>
            <div class="w-28 p-1">
                <select v-model="roomFloorFilter" class="w-full" :disabled="roomFilterFloorOptions.length === 0">
                <option selected :value="null">floor</option>
                <option v-for="floor in roomFilterFloorOptions" :key="floor">{{floor}}</option>
                </select>
            </div>
        </div>
<!-- Room Display -->
        <div v-if="rooms && rooms.length > 1" class="w-full px-2 text-left truncate font-semibold">{{`${rooms.length} rooms`}}</div>
        <div class="w-full flex flex-col flex-grow flex-auto items-center h-0 overflow-y-auto truncate p-1">
            <div class="w-full flex flex-col flex-grow space-y-1 items-center overflow-y-auto">
                <div v-for="room in filteredRooms" :key="room._id" class="w-full p-1">
                    <div class="w-full flex flex-col space-y-2 p-2 border rounded-md truncate">
                        <div class="w-full flex flex-row items-center space-x-1 no-wrap justify-between px-1">
                            <input v-model.trim="room.roomType" type="text" class="w-full max-w-lg" :disabled="isWorking || !allowEdit"
                            @focus="prevValue = room.roomType" @blur="prevValue !== room.roomType ? updateRoom(room._id,'roomType',room.roomType,prevValue) : null"
                            />
                            <div class="flex flex-row space-x-2 items-center justify-center">
                                <div>
                                    <Popper hover arrow placement="top" :content="`av: ${room.avReadinessPercentage}%`">
                                        <button class="bn-icon-small bn-solid" :class="room.avReadinessPercentage === 100 ? 'text-green-700' : room.avReadinessPercentage > 60 ? 'text-yellow-700' : 'text-red-500' "
                                            @click="showReadiness.type === 'av' && showReadiness.id === room._id ? showReadiness = {type:null,id:null} : showReadiness = {type:'av',id:room._id}"
                                            :disabled="isWorking"
                                        >
                                            <XIcon v-if="showReadiness.type === 'av' && showReadiness.id === room._id" />
                                            <ClipboardCheckIcon v-else />
                                        </button>
                                    </Popper>
                                </div>
                                <div>
                                    <Popper hover arrow placement="top" :content="`room: ${room.roomReadinessPercentage}%`">
                                        <button class="bn-icon-small bn-solid" :class="room.roomReadinessPercentage === 100 ? 'text-green-700' : room.roomReadinessPercentage > 60 ? 'text-yellow-700' : 'text-red-500' "
                                            @click="showReadiness.type === 'room' && showReadiness.id === room._id ? showReadiness = {type:null,id:null} : showReadiness = {type:'room',id:room._id}"
                                            :disabled="isWorking"
                                        >
                                            <XIcon v-if="showReadiness.type === 'room' && showReadiness.id === room._id" />
                                            <MdDoorFrontIcon v-else />
                                        </button>
                                    </Popper>
                                </div>
                            </div>
                        </div>
    <!-- Show Readiness -->
                        <div v-if="showReadiness.type === 'av' && showReadiness.id === room._id" class="w-full">
        <!-- Add AV Readiness -->
                            <div class="w-full flex flex-col p-1 space-y-1">
                                <button class="px-2 w-12" @click="showAddAVDetailsId = showAddAVDetailsId === room._id ?  null : room._id" :disabled="isWorking || !allowEdit">
                                    <div class="text-sm">{{`+/-`}}</div>
                                </button>
                                <div v-if="showAddAVDetailsId === room._id" class="w-full">
                                    <div class="w-full flex flex-row flex-nowrap space-x-1 p-1">
                                        <div class="w-44 sm:w-72 flex flex-col space-y-1 panel h-44 p-1 overflow-y-auto">
                                            <div v-for="avTask in room.avReadiness" :key="avTask._id" class="w-full flex flex-row space-x-1 flex-nowrap p-1 truncate flex-shrink-0 cursor-pointer hover:bg-red-500 hover:bg-opacity-30"
                                            @click="!isWorking && allowEdit ? removeAvTask(room._id,avTask._id) : null"
                                            >
                                                <div class="w-full text-left px-1 truncate">{{avTask.avTask}}</div>
                                                <TrashIcon class="w-4 h-4 flex-shrink-0" />
                                            </div>
                                        </div>
                                        <div class="w-44 sm:w-72 flex flex-col space-y-1 panel h-44 p-1 overflow-y-auto">
                                            <div v-for="avTask in roomAvTaskOptions.filter(x => !room.avReadiness.map(a => a.taskCatalogId).includes(x.taskCatalogId))" :key="avTask._id" class="w-full flex flex-row space-x-1 flex-nowrap p-1 truncate flex-shrink-0 cursor-pointer hover:bg-green-700 hover:bg-opacity-30"
                                                @click="!isWorking && allowEdit ? addAvTask(room._id,avTask) : null "
                                            >
                                                <PlusIcon class="w-4 h-4 flex-shrink-0" />
                                                <div class="w-full text-left px-1 truncate">{{avTask.avTask}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        <!-- Show Readiness -->
                            <div v-if="room.avReadiness && room.avReadiness.length > 0" class="w-full flex flex-row items-center flex-wrap md:justify-between p-1">
                                <div v-for="task in room.avReadiness" :key="task._id" class="w-auto p-0.5 self-stretch flex items-center justify-center truncate">
                                    <div class="flex items-center justify-center truncate bg-opacity-70 p-2 rounded-md w-24 self-stretch hover:bg-opacity-30"
                                    :class="[task.complete ? 'bg-green-700': 'bg-red-500',isWorking || !allowEdit ?'cursor-not-allowed' : 'cursor-pointer']"
                                    @click="!isWorking && allowEdit ? toggleAvTask(room._id,task._id,!task.complete) : null"
                                    >
                                    <div class="truncate whitespace-normal">{{task.avTask}}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="w-full items-center p-1 truncate">
                                No AV Tasks Assigned
                            </div>
                        </div>
    <!-- Room Readiness -->
                        <div v-if="showReadiness.type === 'room' && showReadiness.id === room._id" class="w-full flex">
                            <div class="w-full flex flex-row items-center flex-wrap md:justify-between p-1">
                                <div v-for="(task,index) in Object.keys(room.readiness)" :key="index" class="w-auto p-0.5 flex items-center justify-center">
                                    <div class="truncate whitespace-normal bg-opacity-70 p-2 rounded-md w-24 hover:bg-opacity-30"
                                    :class="[room.readiness[task] ? 'bg-green-700': 'bg-red-500', isWorking || !allowEdit ?'cursor-not-allowed' : 'cursor-pointer']"
                                    @click="!isWorking && allowEdit  ? updateRoomReadiness(room._id,task,!room.readiness[task]) : null "
                                    >
                                    {{task}}
                                    </div>
                                </div>
                            </div>
                        </div>
    <!-- Room Detail -->
                        <div class="w-full flex flex-row items-center justify-between">
                            <div class="w-full max-w-lg px-1">
                            <input v-model.trim="room.nameOne" type="text" class="w-full" placeholder="name one" maxlength="50" :disabled="isWorking || !allowEdit" 
                            @focus="prevValue = room.nameOne" @blur="prevValue !== room.nameOne ? updateRoom(room._id,'nameOne',room.nameOne,prevValue): null"
                            />
                            </div>
                            <div class="w-full max-w-lg px-1">
                                <input v-model.trim="room.nameTwo" type="text" class="w-full max-w-lg" placeholder="name two" maxlength="50" :disabled="isWorking || !allowEdit" 
                                @focus="prevValue = room.nameTwo" @blur="prevValue !== room.nameTwo ? updateRoom(room._id,'nameTwo',room.nameTwo,prevValue) :null"
                                />
                            </div>
                        </div>
                        <div class="w-full flex items-center p-1 border-y">
                            <textarea v-model="room.note" placeholder="note" class="w-full max-w-xl resize-none" 
                            @focus="prevValue = room.note" @blur="prevValue !== room.note ? updateRoom(room._id,'note',room.note,prevValue) : null"
                            />
                        </div>
                        <div class="w-full flex flex-row flex-wrap items-center justify-between truncate border-b">
                            <div class="sm:w-1/2 flex flex-row items-center justify-between flex-grow">
                                <div class="flex flex-col space-y-1 p-1">
                                    <input v-model.trim="room.floor" type="text" placeholder="floor" class="w-full max-w-xs" maxlength="20" :disabled="isWorking || !allowEdit" 
                                    @focus="prevValue = room.floor" @blur="prevValue !== room.floor ? updateRoom(room._id,'floor',room.floor,prevValue): null"
                                    />
                                <div class="text-left px-1">floor</div>
                                </div>
                                <div class="w-12 flex flex-col space-y-1 p-1 justify-end">
                                    <input v-model="room.phase" type="number" step="1" min="0" max="100" placeholder="phase" class="w-full text-center" :disabled="isWorking || !allowEdit"
                                    @focus="prevValue = room.phase" @blur="prevValue !== room.phase ? updateRoom(room._id,'phase',room.phase,prevValue) : null"
                                    @input="room.phase = formatNumberInput($event.target?.value,0,1000)"
                                    />
                                <div class="text-left px-1">phase</div>
                                </div>
                            </div>
                            <div class="sm:w-1/2 flex flex-row items-center justify-between flex-grow">
                                <div class="w-30 flex flex-col space-y-1 p-1">
                                    <input v-model="room.startDate" type="date" class="w-full text-center" :disabled="isWorking || !allowEdit"
                                    @click="prevValue = formatISODateforInput(room.startDate)" @blur="prevValue !== $event.target?.value ? updateDate(room._id,'startDate',$event.target.value,prevValue) : null"
                                    />
                                    <div class="text-left px-1">start</div>
                                </div>
                                <div class="w-30 flex flex-col space-y-1 p-1">
                                    <input v-model="room.endDate" type="date" class="w-full text-center" :disabled="isWorking || !allowEdit"
                                    @click="prevValue = formatISODateforInput(room.endDate)" @blur="prevValue !== $event.target?.value ? updateDate(room._id,'endDate',$event.target.value,prevValue) : null"
                                    />
                                    <div class="text-left px-1">end</div>
                                </div>
                                <div class="w-16 flex flex-col space-y-1 p-1">
                                    <input v-model="room.days" type="number" class="w-14 text-right" :disabled="isWorking || !allowEdit"
                                    @click="prevValue = room.days" @blur="prevValue !== room.days ? updateRoom(room._id,'days',room.days,prevValue) : null"
                                    />
                                    <div class="text-right px-1">days</div>
                                </div>
                                <!--<div class="w-30 flex flex-row p-2 self-stretch justify-end flex-shrink-0 truncate">
                                    <div>{{roomDays(room.startDate,room.endDate)}}</div>
                                    <div class="text-left px-1 truncate">days</div>
                                </div>-->
                            </div>
                        </div>
    <!-- Room Options -->
                        <div class="w-full flex flex-row items-center justify-between px-1">
                            <div v-if="room.colorCodeOne !== null" class="flex flex-row space-x-2 items-center">
                                <div>
                                    <Popper hover arrow placement="top" :content="room.colorCodeOne?.name || null ">
                                        <div class="rounded-full h-8 w-8 border-2 flex items-center justify-center font-semibold text-base bg-neutral-300 bg-opacity-75"
                                        :style="`border: 2px solid ${room.colorCodeOne?.backgroundClass};color:${room.colorCodeOne?.backgroundClass}`"
                                        >
                                            {{room.colorCodeOne?.abbreviation}}
                                        </div>
                                    </Popper>
                                </div>
                                <div v-if="room.colorCodeTwo !== null">
                                    <Popper hover arrow placement="top" :content="room.colorCodeTwo?.name || null ">
                                    <div 
                                    class="rounded-full h-8 w-8 border-2 flex items-center justify-center font-semibold text-base bg-neutral-200"
                                    :style="`border: 2px solid ${room.colorCodeTwo?.backgroundClass};color:${room.colorCodeTwo?.backgroundClass}`"
                                    >
                                        {{room.colorCodeTwo?.abbreviation}}
                                    </div>
                                    </Popper>
                                </div>
                                <div>
                                    <Popper hover arrow placement="top" :content="'Assign New Color'">
                                        <button class="bn-icon-small bn-solid" :disabled="isWorking || !allowEdit" @click="getColorCode(room._id)">
                                            <ColorSwatchIcon />
                                        </button>
                                    </Popper>
                                </div>
                            </div>
                            <div v-else>
                                <Popper hover arrow placement="top" :content="'Assign Room Shipping Color'">
                                    <button class="px-1 py-0.5 bn-solid" :disabled="isWorking || !allowEdit" @click="getColorCode(room._id)">
                                        <PlusIcon />
                                        <ColorSwatchIcon />
                                    </button>
                                </Popper>
                            </div>
                            <div class="flex flex-row space-x-2">
                                <div>
                                    <Popper hover arrow placement="top" :content="'Add Rack'">
                                        <button class="bn-icon-small bn-solid" @click="toggleNewRackDialog(newRack.roomId === room._id ? null : room._id)" :disabled="isWorking || !allowEdit">
                                            <XIcon v-if="newRack.roomId === room._id" />
                                            <BsBookshelfIcon v-else />
                                        </button>
                                    </Popper>
                                </div>
                                <DeleteConfirm :size="'small'" @confirmedDelete="deleteRoom(room._id)" :popperContent="'delete room'"
                                :disableButton="isWorking || !allowEdit || (room.roomEquipment && room.roomEquipment.length > 0) || (room.racks && room.racks.length > 0)"
                                />
                            </div>
                        </div>
    <!-- New Rack -->
                        <div v-if="newRack.roomId === room._id" class="w-full flex flex-col p-1 items-center border rounded-md max-w-xl self-center">
                            <div class="w-full text-left p-1 border-b whitespace-normal truncate">New Rack</div>
                            <div class="w-full p-1 flex flex-row truncate items-center justify-center">
                                <div class="w-full flex flex-row truncate items-center p-2 flex-wrap md:justify-between">
                                    <div class="w-80 p-1">
                                        <input v-model.trim="newRack.name" maxlength="60" type="text" placeholder="name" class="w-full" >
                                    </div>
                                    <div class="w-36 p-1">
                                        <select v-model.trim="newRack.rackType" name="" id="" class="w-36">
                                            <option :value="null" hidden selected>Rack Type</option>
                                            <option value="inRoom">In Room</option>
                                            <option value="remote">Remote</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="p-1">
                                    <button class="bn-icon-small bn-solid-green"
                                    :disabled="isWorking || !allowEdit || (!newRack.name || newRack.name.length < 2 || !newRack.rackType)"
                                    @click="saveNewRack(room._id)"
                                    >
                                        <SaveIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
    <!-- Equipment and Racks -->
                        <div v-if="(room.roomEquipment && room.roomEquipment.length > 0) || (room.racks && room.racks.length > 0)" class="w-full flex flex-row items-center justify-between border-t p-1">
                            <div class="flex flex-row space-x-1 items-center">
                                <div v-if="room.roomEquipment && room.roomEquipment.length > 0" class="w-auto truncate flex flex-row p-1 space-x-1 items-center">
                                    <BoxIcon class="h-4 w-4" />
                                    <div class="w-auto truncate">{{room.roomEquipment.length}}</div>
                                </div>
                                <div v-if="room.racks && room.racks.length > 0" class="w-auto truncate flex flex-row p-1 space-x-1 items-center">
                                    <BsBookshelfIcon class="h-4 w-4" />
                                    <div class="w-auto truncate">{{ room.racks.length}}</div>
                                </div>
                            </div>
                            <button v-if="room.racks && room.racks.length > 0" class="px-1 bn-solid" @click="showRacksInRoomId = showRacksInRoomId === room._id ? null : room._id">
                                <BsBookshelfIcon class="w-3 h-3" />
                                <ChevronDownIcon :class="showRacksInRoomId === room._id ? 'rotate-180' : null " />
                            </button>
                        </div>
                        <div v-if="room.racks && room.racks.length > 0 && showRacksInRoomId === room._id" class="w-full p-1 flex flex-col space-y-0.5 items-start max-h-96 overflow-y-auto">
                            <div v-for="rack in room.racks" :key="rack._id" class="w-full flex flex-row space-x-1 max-w-xl border rounded-md items-center px-2 justify-between truncate">
                                <BsBookshelfIcon class="h-4 w-4 flex-shrink-0" />
                                <div class="w-auto flex-grow flex flex-row items-center p-2 flex-wrap justify-between truncate">
                                    <div class="w-80 p-1">
                                        <input v-model.trim="rack.name" maxlength="60" type="text" placeholder="name" class="w-full" 
                                        @focus="prevValue = rack.name" @blur="prevValue !== rack.name ? updateRack(rack._id,room._id,'name',rack.name,prevValue) : null"
                                        :disabled="isWorking || !allowEdit"
                                        >
                                    </div>
                                    <div class="w-28 p-1">
                                        <select v-model.trim="rack.rackType" name="" id="" class="w-28"
                                        @focus="prevValue = rack.rackType" @change="prevValye !== rack.rackType ? updateRack(rack._id,room._id,'rackType',rack.rackType,prevValue) : null"
                                        :disabled="isWorking || !allowEdit"
                                        >
                                            <option :value="null" hidden selected>Rack Type</option>
                                            <option value="inRoom">In Room</option>
                                            <option value="remote">Remote</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="rack.rackState && rack.rackState > 2" class="p-1 icon-only text-green-600">
                                    <Popper hover arrow placement="top" :content="`Built`">
                                        <CheckCircleIcon />
                                    </Popper>
                                </div>
                                <div v-else class="p-1">
                                    <Popper hover arrow placement="top" :content="`Request Build`">
                                        <button class="bn-icon-only" @click="moveRackForward(rack._id,room._id)"
                                        :class="{'text-green-700':rack.rackState && rack.rackState > 0}"
                                        :disabled="isWorking || !allowEdit || (rack.rackEquipment && rack.rackEquipment.length === 0) || (rack.rackState && rack.rackState > 0)"
                                        >
                                            <WrenchSolidIcon />
                                        </button>
                                    </Popper>
                                </div>
                                <div class="p-1">
                                    <DeleteConfirm :size="'small'" :popperContent="'delete rack'" :disableButton="isWorking || !allowEdit || (rack.rackEquipment && rack.rackEquipment.length > 0)" 
                                        @confirmedDelete="deleteRack(rack._id,room._id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- Add Equipment -->
    <Modal v-if="showEquipmentModal" :title="'Room Equipment'" @closeModal="showEquipmentModal = false" >
        <template v-slot:content>
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <!-- Equipment -->
            <div class="w-full flex flex-col flex-grow space-y-1 p-1 items-start justify-start" :style="'min-width:345px'">
                <div class="w-full flex-grow flex flex-row overflow-y-auto flex-auto h-0">
                    <div class="flex flex-col flex-auto min-h-full w-44 sm:w-72 md:w-80 lg:w-96 flex-shrink-0 flex-grow-0 overflow-y-auto p-1 space-y-2 border-r-2">
        <!-- Equipment Filter -->
                        <div class="w-full flex flex-row items-center justify-center p-2 panel space-x-2 flex-shrink-0">
                            <input v-model="equipmentFilter" type="search" class="w-full max-w-md" placeholder="filter">
                            <div class="flex items-center">
                                <Popper hover arrow placement="top" :content="isAllocated ? `allocated` : 'available'">
                                    <button class="bn-icon-small" :class="!isAllocated ? 'bn-solid-green' : 'bn-solid-red'" @click="isAllocated = !isAllocated">
                                        <CheckIcon v-if="!isAllocated" />
                                        <BanIcon v-else />
                                    </button>
                                </Popper>
                            </div>
                        </div>
                        <div class="w-full flex flex-row items-center">
                            <div class="px-1 flex items-center">
                                <button class="bn-icon-only"  @click="expandAll = !expandAll">
                                    <ArrowsExpandIcon />
                                </button>
                            </div>
                            <div class="w-full text-left px-1 max-h-8 whitespace-normal opacity-80">Rooms must be named to appear in list.</div>
                        </div>
        <!-- Display Filtered Equipment -->
            <!-- Equipment -->
                        <div class="w-full flex flex-col space-y-1 flex-grow overflow-y-auto">
                            <div v-if="equipmentByManufacturer && equipmentByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
                                <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Equipment</div>
                                <div v-for="(category,index) in equipmentByManufacturer " :key="index" class="w-full px-1">
                                    <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                                        <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                                        <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                                    </div>
                                    <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                                        <div v-for="(box,index) in category.items" :key="index" class="w-full p-1 truncate" >
                                            <BoxReduced v-if="box.multiBox"
                                            :box="box.multiBox && box.items && box.items.length > 0 ? box.items[0] : box"
                                            :boxItems="box.multiBox && box.items && box.items.length > 0 ? box.items : []"
                                            >
                                                <template v-if="allowEdit" v-slot:actions>
                                                    <div v-if="!box.roomId && !box.rackId" class="w-full flex flex-row items-center justify-end truncate space-x-2 " :class="roomAddOptions && roomAddOptions.length > 0 ? 'p-1 border-t' : null">
                                                        <input v-if="Array.isArray(box.items) && box.items.length > 1 && !isAllocated" type="number" min="1" :max="box.items.length" maxlength="4" value="1"
                                                        class="w-10 text-center"
                                                        @input="$event?.target ? $event.target.value = formatNumberInput($event.target?.value,0,box.items.length) : null"
                                                        :disabled="isWorking"
                                                        >
                                                        <button v-if="Object.keys(showRoomContentsObject).length > 0" class="bn-icon-small bn-solid-sky"
                                                            @click="handleRoomMultiInRoomButton($event,box.items && box.items[0] ? box.items[0]._id : box._id)"
                                                            :disabled="isWorking || (showRoomContentsObject?.rackState  && showRoomContentsObject.rackState > 1)"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                        <select v-model="selectedDestination " v-else-if="roomAddOptions && roomAddOptions.length > 0 && roomAddOptions.length !== 1 && !isAllocated" class="w-full" tabindex="1"
                                                            @change="handleRoomChangeMulti($event,box.items && box.items[0] ? box.items[0]._id : box._id)"
                                                            :disabled="isWorking || box.roomId || box.rackId || roomAddOptions && roomAddOptions.length === 0"
                                                        >
                                                            <option :value="null" hidden>+ to Room</option>
                                                            <option v-for="option in roomAddOptions" :key="option._id" :value="option" >{{option.text}}</option>
                                                        </select>
                                                        <button v-else-if="roomAddOptions && roomAddOptions.length === 1" class="bn-icon-small dark:bn-solid"
                                                        @click="handleRoomMultiSingleRoomButton($event,box.items && box.items[0] ? box.items[0]._id : box._id,{roomId:roomAddOptions[0].roomId})"
                                                        :disabled="isWorking"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                    </div>
                                                    <div v-else class="w-full flex flex-row items-center p-1 justify-end truncate border-t">
                                                        <button class="bn-icon-small bn-solid-red" @click="removeEquipmentFromRoomOrRack(box._id)" :disabled="isWorking || (box.rackId && box.rackId?.rackState > 1)">
                                                            <ArrowSmRightIcon class="rotate-180" />
                                                        </button>
                                                    </div>
                                                </template>
                                            </BoxReduced>
                                            <BoxReduced v-else :box="box">
                                                <template v-if="allowEdit" v-slot:actions>
                                                    <div v-if="!box.roomId && !box.rackId" class="w-full flex flex-row items-center justify-end truncate " :class="roomAddOptions && roomAddOptions.length > 0 ? 'p-1 border-t' : null">
                                                        <button v-if="Object.keys(showRoomContentsObject).length > 0" class="bn-icon-small bn-solid-sky"
                                                            @click="addToRoomDirect(box._id)"
                                                            :disabled="isWorking || (showRoomContentsObject?.rackState && showRoomContentsObject.rackState > 1)"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                        <select v-model="selectedDestination " v-else-if="roomAddOptions && roomAddOptions.length > 0 && roomAddOptions.length !== 1" class="w-full" tabindex="1"
                                                            @change="addToRoomFromSelect(box._id)"
                                                            :disabled="isWorking || box.roomId || box.rackId || roomAddOptions && roomAddOptions.length === 0"
                                                        >
                                                            <option :value="null" hidden>+ to Room</option>
                                                            <option v-for="option in roomAddOptions" :key="option._id" :value="option" >{{option.text}}</option>
                                                        </select>
                                                        <button v-else-if="roomAddOptions && roomAddOptions.length === 1 && (showRoomContents?.rackState && showRoomContents.rackState < 2)" class="bn-icon-small bn-solid"
                                                            @click="moveEquipment(box._id,{roomId:roomAddOptions[0].roomId})"
                                                            :disabled="isWorking"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                    </div>
                                                    <div v-else class="w-full flex flex-row items-center p-1 justify-end truncate border-t">
                                                        <button class="bn-icon-small bn-solid-red" @click="removeEquipmentFromRoomOrRack(box._id)" :disabled="isWorking || (box.rackId && box.rackId?.rackState > 1)">
                                                            <ArrowSmRightIcon class="rotate-180" />
                                                        </button>
                                                    </div>
                                                </template>
                                            </BoxReduced>
                                        </div>
                                    </div>
                                </div>
                            </div>
            <!-- Kits -->
                            <div v-if="kitsByManufacturer && kitsByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
                                <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Kits</div>
                                <div v-for="(category,index) in kitsByManufacturer " :key="index" class="w-full px-1">
                                    <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                                        <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                                        <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                                    </div>
                                    <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                                        <div v-for="(box,index) in category.items" :key="index" class="w-full p-1">
                                            <BoxReduced :box="box">
                                                <template v-if="allowEdit" v-slot:actions>
                                                    <div v-if="!box.roomId && !box.rackId" class="w-full flex flex-row items-center justify-end truncate " :class="roomAddOptions && roomAddOptions.length > 0 ? 'p-1 border-t' : null">
                                                        <button v-if="Object.keys(showRoomContentsObject).length > 0" class="bn-icon-small bn-solid-sky"
                                                            @click="addToRoomDirect(box._id)"
                                                            :disabled="isWorking || (showRoomContents?.rackState && showRoomContentsObject.rackState > 1)"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                        <select v-model="selectedDestination " v-else-if="roomAddOptions && roomAddOptions.length > 0 && roomAddOptions.length !== 1" class="w-full" tabindex="1"
                                                            @change="addToRoomFromSelect(box._id)"
                                                            :disabled="isWorking || box.roomId || box.rackId || roomAddOptions && roomAddOptions.length === 0"
                                                        >
                                                            <option :value="null" hidden>+ to Room</option>
                                                            <option v-for="option in roomAddOptions" :key="option._id" :value="option" >{{option.text}}</option>
                                                        </select>
                                                        <button v-else-if="roomAddOptions && roomAddOptions.length === 1" class="bn-icon-small dark:bn-solid"
                                                        @click="moveEquipment(box._id,{roomId:roomAddOptions[0].roomId})"
                                                        :disabled="isWorking"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                    </div>
                                                    <div v-else class="w-full flex flex-row items-center p-1 justify-end truncate border-t">
                                                        <button class="bn-icon-small bn-solid-red" @click="removeEquipmentFromRoomOrRack(box._id)" :disabled="isWorking || (box.rackId && box.rackId?.rackState > 1)">
                                                            <ArrowSmRightIcon class="rotate-180" />
                                                        </button>
                                                    </div>
                                                </template>
                                                <template v-slot:kitBoxes>
                                                    <div v-for="kitBox in box.items" :key="kitBox._id" class="w-full pl-1">
                                                        <BoxReduced :box="kitBox">
                                                            <template v-if="allowEdit" v-slot:actions>
                                                                <div class="w-full flex items-center justify-end border-t py-1">
                                                                    <DeleteConfirm :size="'small'" :iconComponent="XIcon" :popperContent="'remove from kit'" @confirmedDelete="removeFromKit(kitBox._id)"
                                                                    :disableButton="isWorking"
                                                                    />
                                                                </div>
                                                            </template>
                                                        </BoxReduced>
                                                    </div>
                                                </template>
                                            </BoxReduced>
                                        </div>
                                    </div>
                                </div>
                            </div>
            <!-- Accessories -->
                            <!--<div v-if="accessoriesByManufacturer && accessoriesByManufacturer.length > 0" class="w-full flex flex-col space-y-1">
                                <div class="w-full py-1 px-2 text-left truncate panel sticky top-0 z-10">Accessories</div>
                                <div v-for="(category,index) in accessoriesByManufacturer" :key="index" class="w-full px-1">
                                    <div class="w-full p-1 flex flex-row space-x-2 truncate font-semibold has-details" @click="openDetails($event.target)">
                                        <div class="w-auto whitespace-normal truncate">{{category.name}}</div>
                                        <div v-if="category.items && category.items.length > 0" class="w-auto">{{category.items.length}}</div>
                                    </div>
                                    <div v-if="category.items && category.items.length > 0 " class="w-full whitespace-normal truncate p-1" :class="{'hidden' :!expandAll}">
                                        <div v-for="(box,index) in category.items" :key="index" class="p-1">
                                            <BoxReduced 
                                            :box="box.multiBox && box.items && box.items.length > 0 ? box.items[0] : box"
                                            :boxItems="box.multiBox && box.items && box.items.length > 0 ? box.items : []"
                                            >
                                                <template v-if="allowEdit" v-slot:actions>
                                                    <div v-if="!box.roomId && !box.rackId" class="w-full flex flex-row items-center justify-end truncate space-x-2 " :class="roomAddOptions && roomAddOptions.length > 0 ? 'p-1 border-t' : null">
                                                        <input v-if="Array.isArray(box.items) && box.items.length > 1 && !isAllocated" type="number" min="1" :max="box.items.length" maxlength="4" value="1"
                                                        class="w-10 text-center"
                                                        @input="$event?.target ? $event.target.value = formatNumberInput($event.target?.value,0,box.items.length) : null"
                                                        :disabled="isWorking"
                                                        >
                                                        <button v-if="Object.keys(showRoomContentsObject).length > 0" class="bn-icon-small bn-solid-sky"
                                                            @click="handleRoomMultiInRoomButton($event,box.items && box.items[0] ? box.items[0]._id : box._id)"
                                                            :disabled="isWorking || (showRoomContentsObject?.rackState  && showRoomContentsObject.rackState > 1)"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                        <select v-model="selectedDestination " v-else-if="roomAddOptions && roomAddOptions.length > 0 && roomAddOptions.length !== 1 && !isAllocated" class="w-full" tabindex="1"
                                                            @change="handleRoomChangeMulti($event,box.items && box.items[0] ? box.items[0]._id : box._id)"
                                                            :disabled="isWorking || box.roomId || box.rackId || roomAddOptions && roomAddOptions.length === 0"
                                                        >
                                                            <option :value="null" hidden>+ to Room</option>
                                                            <option v-for="option in roomAddOptions" :key="option._id" :value="option" >{{option.text}}</option>
                                                        </select>
                                                        <button v-else-if="roomAddOptions && roomAddOptions.length === 1" class="bn-icon-small dark:bn-solid"
                                                        @click="handleRoomMultiSingleRoomButton($event,box.items && box.items[0] ? box.items[0]._id : box._id,{roomId:roomAddOptions[0].roomId})"
                                                        :disabled="isWorking"
                                                        >
                                                            <ArrowSmRightIcon />
                                                        </button>
                                                    </div>
                                                    <div v-else class="w-full flex flex-row items-center p-1 justify-end truncate border-t">
                                                        <button class="bn-icon-small bn-solid-red" @click="removeEquipmentFromRoomOrRack(box._id)" :disabled="isWorking || (box.rackId && box.rackId?.rackState > 1)">
                                                            <ArrowSmRightIcon class="rotate-180" />
                                                        </button>
                                                    </div>
                                                </template>
                                            </BoxReduced>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>
    <!-- Rooms -->
        <!-- Show All Rooms -->
                    <div v-show="Object.keys(showRoomContentsObject).length === 0" class="w-full flex flex-col flex-grow space-y-1 p-1 overflow-x-auto sm:overflow-x-clip">
            <!-- Room Filter 2 -->
                        <div v-if="rooms && rooms.length > 0" class="w-full p-1 flex items-center justify-start lg:justify-center panel truncate flex-wrap flex-shrink-0">
                            <div class="w-full max-w-xs p-1">
                                <input v-model="roomFilter" type="search" placeholder="filter rooms" class="w-full">
                            </div>
                            <div class="w-36 flex-shrink-0 p-1">
                                <select v-model="roomFloorFilter" class="w-full" :disabled="roomFilterFloorOptions.length === 0">
                                    <option selected :value="null">floor</option>
                                    <option v-for="floor in roomFilterFloorOptions" :key="floor">{{floor}}</option>
                                </select>
                            </div>
                            <div class="w-18 flex-shrink-0 p-1">
                                <select v-model="roomPhaseFilter" class="w-full" :disabled="roomFilterPhaseOptions.length === 0">
                                    <option selected :value="null">phase</option>
                                    <option v-for="phase in roomFilterPhaseOptions" :key="phase">{{phase}}</option>
                                </select>
                            </div>
                        </div>
            <!-- Show Rooms to Populate -->
                        <div class="w-full flex flex-col items-start sm:items-center space-y-1 flex-grow overflow-y-auto">
                            <div v-for="room in filteredRooms" :key="room._id" class="sm:w-full sm:flex-shrink max-w-2xl w-96 flex-shrink-0">
                                <RoomDisplay :room="room" :showLink="true" @showContents="showRoomContents = $event" />
                            </div>
                        </div>
                    </div>
        <!-- Show Individual Room or Rack -->
                    <div v-show="Object.keys(showRoomContentsObject).length > 0" class="w-96 flex flex-col flex-grow space-y-1 p-1 truncate overflow-x-auto">
                            <div class="w-full flex flex-col flex-grow p-1 rounded-md border overflow-x-auto">
                                <div class="sm:w-full sm:flex-shrink w-96 flex-shrink-0 flex flex-col flex-grow space-y-1 items-center">
                                    <div class="w-full flex items-center p-1 justify-end">
                                        <button class="bn-icon-small bn-solid-sky" @click="showRoomContents = null">
                                            <ExternalLinkIcon class="rotate-180" />
                                        </button>
                                    </div>
                                    <div class="w-full flex flex-col flex-grow p-1 overflow-y-auto">
                                        <div class="w-full p-1 flex flex-col space-y-1 border-b flex-shrink-0">
                                            <div v-if="showRoomContentsObject?.roomId" class="w-full flex flex-row justify-between p-1">
                                                <div class="w-auto text-left whitespace-normal truncate">{{showRoomContentsObject?.name}}</div>
                                                <div class="w-auto text-left whitespace-normal truncate">{{showRoomContentsObject?.rackType}}</div>
                                            </div>
                                            <div v-else class="w-full flex flex-col space-y-1 p-1">
                                                <div class="w-full text-left whitespace-normal truncate">{{showRoomContentsObject?.roomType}}</div>
                                                <div class="w-full flex flex-row items-center flex-wrap justify-between truncate">
                                                    <div class="w-auto p-1 text-left whitespace-normal truncate">{{showRoomContentsObject?.nameOne}}</div>
                                                    <div class="w-auto p-1 text-left whitespace-normal truncate">{{showRoomContentsObject?.nameTwo}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full p-1 flex flex-col flex-grow overflow-y-auto space-y-1 items-center">
                                            <RoomContents :equipment="showRoomContentsObject?.rackEquipment || showRoomContentsObject?.roomEquipment" :showBoxActions="allowEdit && (showRoomContentsObject?.rackEquipment ? showRoomContentsObject?.rackState < 2 :true)" 
                                            @removeItem="removeEquipmentFromRoomOrRack($event)" :racks="!showRoomContentsObject?.rackEquipment ? showRoomContentsObject.racks : []"
                                            @moveToRack="moveToRackFromRoom($event)" @moveToRoom="moveToRoomFromRack($event)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import api from '@/api'
import {isValid,parseISO} from "date-fns"
import {PlusIcon, SaveIcon,XIcon,ClipboardCheckIcon,CogIcon,CheckIcon,ArrowSmRightIcon,ChevronDownIcon,ExternalLinkIcon,ArrowsExpandIcon,CheckCircleIcon,MenuAlt2Icon,BanIcon} from "@heroicons/vue/solid"
import {ColorSwatchIcon,TrashIcon} from "@heroicons/vue/outline"
import {formatNumberInput,openDetails,formatISODateforInput,dateInputToISO,getItemsByManufacturer} from '@/shared'
import RoomDisplay from '@/components/projects/rooms/RoomDisplay.vue'
import MdDoorFrontIcon from "@/components/customIcons/MdDoorFrontIcon.vue"
import BsBookshelfIcon from '@/components/customIcons/BsBookshelfIcon.vue'
import BoxIcon from '@/components/customIcons/BoxIcon.vue'
import MultiSelect from '@/components/ui/MultiSelect.vue'
import BoxReduced from '@/components/cards/BoxReduced.vue'
import RoomContents from '@/components/projects/rooms/RoomContents.vue'
import WrenchSolidIcon from '@/components/customIcons/WrenchSolidIcon.vue'

export default {
    props: {
        project:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false}
    },
    components:{RoomDisplay,MultiSelect,BoxReduced,RoomContents,PlusIcon,SaveIcon,XIcon,ColorSwatchIcon,ClipboardCheckIcon,MdDoorFrontIcon,BsBookshelfIcon,TrashIcon,BoxIcon,CogIcon,CheckIcon,ArrowSmRightIcon,ChevronDownIcon,ExternalLinkIcon,ArrowsExpandIcon,WrenchSolidIcon,CheckCircleIcon,MenuAlt2Icon,BanIcon},
    setup (props) {
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const isGettingEquipment = ref(false)

        const rooms = ref([])
        const equipment = ref([])

        const newRoomInitial = {
            roomType:null,
            avReadiness:[]
        }
        const showNewRoomDialog = ref(false)
        const newRoomNumberOf = ref(1)
        const newRoom = ref(newRoomInitial)
        const roomAvTaskOptions = ref([])

        const showRoomNamesDialog = ref(false)
        const renameRooms = ref({type:null,to:null})
        const nameUnnamedRooms = ref({type:null,to:null})

        const roomFilter = ref(null)
        const roomPhaseFilter = ref(null)
        const roomFloorFilter = ref(null)

        const showReadiness = ref({type:null,id:null})
        const showAddAVDetailsId = ref(null)
        const newRackInitial = {
            roomId:null,
            name:null,
            rackType:null
        }
        const newRack = ref(newRackInitial)
        const showRacksInRoomId = ref(null)

        const showEquipmentModal = ref(false)
        const equipmentFilter = ref(null)
        const isAllocated = ref(false)
        const selectedDestination = ref(null)

        const showRoomContents = ref({roomId:null,rackId:null})

        const expandAll = ref(false)

        onMounted(()=>{
            getRooms()
            getRoomAVOptions()
            getProjectEquipment()
            window.addEventListener("data_change_from_socket",handleSocketChange)
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const newRoomTypeOptions = computed(()=>{
            return [...new Set(rooms.value.map(room=>room.roomType))]
        })

        const equipmentFiltered = computed(()=>{
            let filteredByAllocation = equipment.value.filter(item=>isAllocated.value ? (item.roomId || item.rackId) : (!item.roomId && !item.rackId))

            if(equipmentFilter.value) {
                let filter = equipmentFilter.value.toLowerCase()
                filteredByAllocation = filteredByAllocation.filter(item=>
                    (
                        (item.manufacturer && item.manufacturer.toLowerCase().includes(filter)) ||
                        (item.partNumber && item.partNumber.toLowerCase().includes(filter)) ||
                        (item.deviceType && item.deviceType.toLowerCase().includes(filter)) ||
                        (item.description && item.description.toLowerCase().includes(filter))
                    )
                )
            }
                        // Collect kitIds from filtered items
            const kitIds = new Set(filteredByAllocation.map(item => item.kitId).filter(kitId => kitId));

            // Include all items with a kitId that matches any kitId in the filtered results
            const withKitIds = equipment.value.filter(item => kitIds.has(item.kitId));

            // Combine filtered items with those that have matching kitIds, avoiding duplicates
            const combined = [...filteredByAllocation, ...withKitIds.filter(item => !filteredByAllocation.includes(item))];

            return combined;
        })

        const roomAddOptions = computed(()=>{
            let options = [];
            let namedRooms = filteredRooms.value.filter(room=>room.nameOne || room.nameTwo).sort((a, b) => {
                // Use nameOne if it exists, otherwise use nameTwo for comparison
                let nameA = a.nameOne || a.nameTwo;
                let nameB = b.nameOne || b.nameTwo;

                // Compare the two names
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            namedRooms.forEach(room => {
            // Add room as an option
            options.push({ id: room._id, text: room.nameOne || room.nameTwo, roomId: room._id,rackId:null });
            // If the room has racks, add them as sub-options
            if (room.racks && room.racks.length > 0) {
                room.racks.forEach(rack => {
                    if(!rack.rackState || (rack.rackState && rack.rackState < 2)) {
                        options.push({ id: rack._id, text: ` --${rack.name}`, roomId: null,rackId:rack._id  });
                    }
                });
            }
            });
            return options;
        })

        const equipmentByManufacturer = computed(() => {
            return getItemsByManufacturer(equipmentFiltered.value,'equipment')
        });

        const kitsByManufacturer = computed(() => {
            return getItemsByManufacturer(equipmentFiltered.value,'kits')
        });

        //const accessoriesByManufacturer = computed(() => {
        //    return getItemsByManufacturer(equipmentFiltered.value,'accessories')
        //});

        const showRoomContentsObject = computed(()=>{
            if(showRoomContents.value && showRoomContents.value.roomId) {
                let type = showRoomContents.value.rackId ? 'rack' : 'room'
                let roomObject = rooms.value.find(r=>r._id === showRoomContents.value.roomId)
                if(!roomObject) {
                    return {}
                } else {
                    if(type === 'room') {
                        return roomObject
                    } else if (type === 'rack' && roomObject.racks && roomObject.racks.length > 0) {
                        let rackObject = roomObject.racks.find(ra=>ra._id === showRoomContents.value.rackId)
                        return rackObject || {}
                    } else {
                        return {}
                    }
                }
            } else {
                return {}
            }
            
        })

        //const roomDays = (start,end)=>{
        //    if(start && isValid(parseISO(start)) && end && isValid(parseISO(start))) {
        //        return differenceInBusinessDays(parseISO(end),parseISO(start))
        //    } else {
        //        return 0
        //    }
        //}

        const toggleNewRoomDialog = ()=>{
            if(showRoomNamesDialog.value) {toggleRoomNamesDialog()}
            newRoom.value = {...newRoomInitial}
            newRoom.value.avReadiness = roomAvTaskOptions.value.filter(x=>x.defaultOption)
            newRoomNumberOf.value = 1
            showNewRoomDialog.value = !showNewRoomDialog.value
        }

        const toggleRoomNamesDialog = ()=>{
            if(showNewRoomDialog.value) {toggleNewRoomDialog()}
            renameRooms.value = {type:null,to:null}
            nameUnnamedRooms.value = {type:null,to:null}
            showRoomNamesDialog.value = !showRoomNamesDialog.value
        }

        const getRooms = async (type)=>{
            startAPI(type)
            await api
            .get("rooms/roomsByProject/" + props.project._id)
            .then((res)=>{
                if(res.data?.data) {
                    rooms.value = processDates(res.data.data)
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const getProjectEquipment = async (type)=>{
            type !== 'silent' ? isGettingEquipment.value = true : null
            await api
            .get(`equipment/${props.project._id}`)
            .then((res)=>{
                Array.isArray(res.data?.data)
                ? equipment.value = res.data.data
                :null
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isGettingEquipment.value = false
            })
            
        }

        const getRoomAVOptions = async ()=>{
            await api
            .get('avTask')
            .then((res)=>{
                res.data?.data
                ? roomAvTaskOptions.value = res.data?.data
                .map((x)=> {return {'avTask':x.avTask,'displayOrder':x.displayOrder,'taskCatalogId':x._id,defaultOption:x.defaultOption}})
                : null
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        const saveNewRoom = async ()=>{
            startAPI()
            let numberToSave = 1
            newRoomNumberOf.value && Number(newRoomNumberOf.value)
            ? numberToSave = newRoomNumberOf.value < 1 ? 1 : Math.round(newRoomNumberOf.value)
            : null

            let newRoomsArray = []
            for (let i = 0; i < numberToSave; i++) {
                // Clone newRoom.value for each new room to avoid reference issues.
                const roomData = newRoom.value
                roomData.projectId = props.project._id
                newRoomsArray.push(roomData);
            }
            await api
            .post("rooms/create",newRoomsArray)
            .then(async ()=>{
                await getRooms('silent')
                toggleNewRoomDialog()
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const renameRoomsInProject = async ()=>{
            startAPI()
            await api
            .post(`rooms/renameRooms/${props.project._id}`,renameRooms.value)
            .then((res)=>{
                if(Array.isArray(res.data.data)) {
                    updateMultipleRooms(res.data.data)
                }
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id})
                toggleRoomNamesDialog()
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const nameUnnamedRoomsInProject = async ()=>{
            startAPI()
            await api
            .post(`rooms/nameUnnamedRooms/${props.project._id}`,nameUnnamedRooms.value)
            .then((res)=>{
                if(Array.isArray(res.data.data)) {
                    updateMultipleRooms(res.data.data)
                }
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id})
                toggleRoomNamesDialog()
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateMultipleRooms = (array)=>{
            if(Array.isArray(array)) {
                array.forEach(room=>{
                    let roomIndex = rooms.value.findIndex(x=>x._id === room._id)
                    if(roomIndex > -1) {
                        rooms.value[roomIndex] = processDates(room)
                    }
                })
                rooms.value.sort((a, b) => a.roomType.localeCompare(b.roomType))
            }
        }

        const saveNewRack = async ()=>{
            startAPI()
            await api
            .post("racks/create",newRack.value)
            .then((res)=>{
                let roomIndex = rooms.value.findIndex(x=>x._id === res.data.data.roomId)
                if(roomIndex > -1 && rooms.value[roomIndex].racks && Array.isArray(rooms.value[roomIndex].racks)) {
                    rooms.value[roomIndex].racks.push(res.data.data)
                }
                newRack.value = newRackInitial
                sendChangeEvent('rack',props.project._id,'',{projectId:props.project._id,roomId:res.data?.data?.roomId?._id || res.data?.data?.roomId ,rackId:res.data?.data?._id,shipmentId:res.data.data?.shipmentId?._id,rackState:res.data?.data?.rackState})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateRack = async (rackId,roomId,key,value,prevValue)=>{
            startAPI()
            let body = {[key]:value}
            let roomIndex = rooms.value.findIndex(x=>x._id === roomId)
            await api
            .put(`racks/${rackId}`,body)
            .then((res)=>{
                if(roomIndex > -1 && res.data?.data && typeof res.data.data === 'object') {
                    if (rooms.value[roomIndex].racks) {
                        let rackIndex = rooms.value[roomIndex].racks.findIndex(r=>r._id === rackId)
                        rackIndex > -1
                        ? rooms.value[roomIndex].racks[rackIndex] = res.data.data
                        : null
                    }
                }
                sendChangeEvent('rack',props.project._id,'',{projectId:props.project._id,roomId:roomId,rackId:res.data?.data?._id,shipmentId:res.data.data?.shipmentId?._id,rackState:res.data?.data?.rackState})
            })
            .catch((err)=>{
                if(roomIndex > -1) {
                    if (rooms.value[roomIndex].racks) {
                        let rackIndex = rooms.value[roomIndex].racks.findIndex(r=>r._id === rackId)
                        rackIndex > -1
                        ? rooms.value[roomIndex].racks[rackIndex][key] = prevValue
                        : null
                    }
                }
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const moveRackForward = async (rackId,roomId)=>{
            startAPI()
            let roomIndex = rooms.value.findIndex(x=>x._id === roomId)
            await api
            .put(`racks/state/forward/${rackId}`)
            .then((res)=>{
                if(roomIndex > -1 && res.data?.data && typeof res.data.data === 'object') {
                    if (rooms.value[roomIndex].racks) {
                        let rackIndex = rooms.value[roomIndex].racks.findIndex(r=>r._id === rackId)
                        rackIndex > -1
                        ? rooms.value[roomIndex].racks[rackIndex] = res.data.data
                        : null
                    }
                }
                sendChangeEvent('rack',props.project._id,'',{projectId:props.project._id,roomId:roomId,rackId:res.data?.data?._id,shipmentId:res.data.data?.shipmentId?._id,rackState:res.data?.data?.rackState})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const deleteRack = async (id,roomId)=>{
            startAPI()
            let roomIndex = rooms.value.findIndex(x=>x._id === roomId)
            await api
            .delete(`racks/${id}`)
            .then((res)=>{
                if(roomIndex > -1 && res.data?.data && typeof res.data.data === 'object') 
                {
                    if (rooms.value[roomIndex].racks) {
                        let rackIndex = rooms.value[roomIndex].racks.findIndex(r=>r._id === id)
                        rackIndex > -1
                        ? rooms.value[roomIndex].racks.splice(rackIndex,1)
                        : null
                    }
                }
                sendChangeEvent('rack',props.project._id,'',{projectId:props.project._id,roomId:roomId,rackId:res.data?.data?._id,shipmentId:res.data.data?.shipmentId?._id,rackState:res.data?.data?.rackState})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateRoom = async (id,key,value,prevValue)=>{
            startAPI()
            let body = {
                [key]:value
            }
            let roomIndex = rooms.value.findIndex(r=>r._id === id )
            await api
            .put("rooms/" + id,body)
            .then((res)=>{
                if(roomIndex > -1) {
                    if(res.data?.data) {
                        rooms.value[roomIndex] = processDates(res.data.data)
                    }
                }
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id,roomId:res.data?.data?._id})
            })
            .catch((err)=>{
                roomIndex > -1 ? rooms.value[roomIndex][key] = prevValue : null
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const deleteRoom = async (id)=>{
            startAPI()
            let roomIndex = rooms.value.findIndex(r=>r._id === id )
            await api
            .delete(`rooms/${id}`)
            .then(()=>{
                roomIndex > -1
                ? rooms.value.splice(roomIndex,1)
                : null
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id,roomId:id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateRoomReadiness =  async (id,key,value)=>{
            startAPI()
            let body = {
                [key]:value
            }
            let roomIndex = rooms.value.findIndex(r=>r._id === id )
            await api
            .put("rooms/roomReadiness/" + id,body)
            .then((res)=>{
                if(roomIndex > -1) {
                    if(res.data?.data) {
                        rooms.value[roomIndex] = processDates(res.data.data)
                    }
                }
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id,roomId:res.data?.data?._id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const addAvTask = async (id,task)=>{
            startAPI()

            await api
            .post(`rooms/avReadinessTask/${id}`,task)
            .then((res)=>{
                let roomIndex = rooms.value.findIndex(r=>r._id === id )
                if(roomIndex > -1) {
                    if(res.data?.data) {
                        rooms.value[roomIndex] = processDates(res.data.data)
                    }
                }
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id,roomId:res.data?.data?._id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const removeAvTask = async (roomId,avTaskId)=>{
            startAPI()
            await api
            .delete(`rooms/avReadinessTask/${roomId}/${avTaskId}`)
            .then((res)=>{
                let roomIndex = rooms.value.findIndex(r=>r._id === roomId )
                if(roomIndex > -1) {
                    if(res.data?.data) {
                        rooms.value[roomIndex] = processDates(res.data.data)
                    }
                }
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id,roomId:res.data?.data?._id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const toggleAvTask = async (roomId,avTaskId,value)=>{
            startAPI()
            let body = {complete:value}
            await api
            .put(`rooms/avReadinessTask/${roomId}/${avTaskId}`,body)
            .then((res)=>{
                let roomIndex = rooms.value.findIndex(r=>r._id === roomId )
                if(roomIndex > -1) {
                    if(res.data?.data) {
                        rooms.value[roomIndex] = processDates(res.data.data)
                    }
                }
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id,roomId:res.data?.data?._id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const getColorCode = async (id)=>{
            startAPI()
            await api
            .get(`rooms/getRoomColor/${id}`)
            .then((res)=>{
                let roomIndex = rooms.value.findIndex(r=>r._id === id )
                if(roomIndex > -1) {
                    if(res.data?.data) {
                        rooms.value[roomIndex] = processDates(res.data.data)
                    }
                }
                sendChangeEvent('room',props.project._id,'',{projectId:props.project._id,roomId:res.data?.data?._id})
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateDate = (id,key,value,prevValue) => {
            let convertedDate =  dateInputToISO(value)
            updateRoom(id,`${key}`,convertedDate, prevValue);
        }

        const filteredRooms = computed(()=>{

            if(!roomFilter.value && !roomPhaseFilter.value && !roomFloorFilter.value) {
                return rooms.value
            } else {
                return rooms.value.filter(room => {

                let matchesRoomFilter = true
                if(roomFilter.value) {
                    let filter = roomFilter.value.toLowerCase()
                    matchesRoomFilter = (room.nameOne && room.nameOne.toLowerCase().includes(filter)) || 
                    (room.nameOne && room.nameTwo.toLowerCase().includes(filter)) ||
                    (room.roomType && room.roomType.toLowerCase().includes(filter)) ||
                    (room.racks && room.racks.some(rack => rack.name && rack.name.toLowerCase().includes(filter)))
                }

                // Check for roomPhaseFilter
                let matchesPhaseFilter = true;
                if (roomPhaseFilter.value && room.phase) {
                    matchesPhaseFilter = roomPhaseFilter.value == room.phase;
                }

                // Check for roomFloorFilter
                let matchesFloorFilter = true;
                if (roomFloorFilter.value) {
                    matchesFloorFilter = roomFloorFilter.value == room.floor;
                }

                return matchesRoomFilter && matchesPhaseFilter && matchesFloorFilter

                })
            }
        })

        const roomFilterPhaseOptions = computed(()=>{
            return [...new Set(rooms.value.filter(f=>f.phase).map(x=>x.phase).sort())]
        })

        const roomFilterFloorOptions = computed(()=>{
            return [...new Set(rooms.value.filter(f=>f.floor).map(x=>x.floor).sort((a,b)=>{
                // Convert to numbers if both are numeric
                const numA = isNaN(a) ? a : Number(a);
                const numB = isNaN(b) ? b : Number(b);

                // Compare numbers as numbers and strings as strings
                if (typeof numA === 'number' && typeof numB === 'number') {
                return numA - numB;
                } else {
                return numA.toString().localeCompare(numB.toString(), undefined, { numeric: true, sensitivity: 'base' });
                }
            }
            ))]
        })

        const toggleNewRackDialog = (id)=>{
            newRack.value = {...newRackInitial}
            newRack.value.roomId = id
        }

        const handleRoomChangeMulti = (event,boxId)=>{
            let previousSiblingValue = event?.target?.previousElementSibling?.value
            if(previousSiblingValue && previousSiblingValue >1) {
                addToRoomFromSelectMulti(boxId,previousSiblingValue)
                event?.target?.previousElementSibling?.value ? event.target.previousElementSibling.value = 1 : null
            } else {
                addToRoomFromSelect(boxId)
            }
        }

        const handleRoomMultiSingleRoomButton = (event,boxId,body)=>{
            let previousSiblingValue = event?.currentTarget?.previousElementSibling?.value
            if(previousSiblingValue && previousSiblingValue >1) {
                body.amount = previousSiblingValue
                moveEquipmentMulti(boxId,body)
                event?.target?.previousElementSibling?.value ? event.target.previousElementSibling.value = 1 : null
            } else {
                moveEquipment(boxId,body)
            }
        }

        const handleRoomMultiInRoomButton = (event,boxId)=>{
            let previousSiblingValue = event?.currentTarget?.previousElementSibling?.value
            if(previousSiblingValue && previousSiblingValue >1) {
                let target = showRoomContentsObject.value
                let body = {}
                body.amount = previousSiblingValue
                target.roomId ? body.rackId = target._id : body.roomId = target._id
                moveEquipmentMulti(boxId,body)
                event?.target?.previousElementSibling?.value ? event.target.previousElementSibling.value = 1 : null
            } else {
                addToRoomDirect(boxId)
            }
        }

        const addToRoomFromSelect = async(id)=>{
            let target = selectedDestination.value
            selectedDestination.value = null
            let body = {}
            target.rackId ? body.rackId = target.rackId : body.roomId = target.roomId
            moveEquipment(id,body)
        }

        const addToRoomFromSelectMulti = async (id,amount)=>{
            let target = selectedDestination.value
            selectedDestination.value = null
            let body = {
                amount
            }
            target.rackId ? body.rackId = target.rackId : body.roomId = target.roomId
            moveEquipmentMulti(id,body)
        }

        const moveToRackFromRoom = async (event)=>{
            startAPI()
            let body = {rackId:event.rackId}
            await api
            .put(`equipment/moveEquipment/roomToRack/${event.id}`,body)
            .then((res)=>{
                updateRoomAndEquipmentData(res)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const moveToRoomFromRack = async (event)=>{
            startAPI()
            await api
            .put(`equipment/moveEquipment/rackToRoom/${event}`,{})
            .then((res)=>{
                updateRoomAndEquipmentData(res)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const addToRoomDirect = (id)=>{
            let target = showRoomContentsObject.value
            let body = {}
            target.roomId ? body.rackId = target._id : body.roomId = target._id
            moveEquipment(id,body)
        }

        const removeEquipmentFromRoomOrRack = async (id)=>{
            startAPI()
            await api
            .put(`equipment/moveEquipment/remove/${id}`)
            .then((res)=>{
                updateRoomAndEquipmentData(res)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const moveEquipment = async (id,body)=>{
            startAPI()
            await api
            .put(`equipment/moveEquipment/add/${id}`,body)
            .then((res)=>{
                updateRoomAndEquipmentData(res)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const moveEquipmentMulti = async (id,body)=>{
            startAPI()
            await api
            .put(`equipment/moveEquipmentMulti/add/${id}`,body)
            .then((res)=>{
                updateRoomAndEquipmentData(res)
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const updateRoomAndEquipmentData = (res)=>{
            if(res.data?.data && typeof res.data.data === 'object') {
                if (Array.isArray(res.data.data.equipment)) {
                    res.data.data.equipment.forEach(equip => {
                        let equipmentIndex = equipment.value.findIndex(x => x._id === equip._id);
                        if (equipmentIndex > -1) {
                            equipment.value[equipmentIndex] = equip;
                        }
                    });
                } else {
                    let equipmentIndex = equipment.value.findIndex(x => x._id === res.data.data.equipment._id);
                    if (equipmentIndex > -1) {
                        equipment.value[equipmentIndex] = res.data.data.equipment;
                    }
                }
                sendChangeEvent('equipment',props.project._id,'',{projectId:props.project._id})
                if(res.data?.data?.room) {
                    let roomIndex = rooms.value.findIndex(x=>x._id === res.data.data.room._id)
                    roomIndex > -1
                    ? rooms.value[roomIndex] = processDates(res.data.data.room)
                    :null
                    sendChangeEvent('room',props.project._id,'',{projectId:props.project._id,roomId:res.data?.data?.room?._id})
                }
            }
        }

        const removeFromKit = async (id)=>{
            startAPI()
            await api
            .put(`equipment/removeFromKit/${id}`,{})
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    let equipmentIndex = equipment.value.findIndex(x=>x._id === res.data.data._id)
                    if(equipmentIndex > -1) {
                        equipment.value[equipmentIndex] = res.data.data
                    }
                    sendChangeEvent('equipment',props.project._id,'',{projectId:props.project._id})
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }
        const startAPI = (type)=>{
            type !== 'silent' ? isWorking.value = true : null
            setModalBlocked(true)
            responseError.value = null
        }

        const stopAPI = ()=>{
            isWorking.value = false
            setModalBlocked(false)
        }

        const processDates = (val)=>{
            if(val && Array.isArray(val)) {
                return val.map(obj => {
                    return {
                        ...obj,
                        startDate: formatISODateforInput(obj.startDate),
                        endDate: formatISODateforInput(obj.endDate)
                    }
                })
            } else if (typeof val === 'object') {
                val.startDate = formatISODateforInput(val.startDate)
                val.endDate = formatISODateforInput(val.endDate)
                return val
            } else {
                return val
            }
        }

//        const groupItemsByManufacturer = (items) =>{
//
//            const sortedItems = items.sort((a, b) => {
//                const manufacturerCompare = a.manufacturer.localeCompare(b.manufacturer);
//                if (manufacturerCompare !== 0) return manufacturerCompare;
//                return a.boxNumber - b.boxNumber; // Assuming boxNumber is numerical
//            });
//
//            const manufacturers = [];
//
//            sortedItems.forEach(item => {
//                let manufacturer = manufacturers.find(m => m.name === item.manufacturer);
//                if (!manufacturer) {
//                manufacturer = { name: item.manufacturer, items: [] };
//                manufacturers.push(manufacturer);
//                }
//                manufacturer.items.push(item);
//            });
//            return manufacturers;
//        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'room':
                        if(e.detail.data?.projectId === props.project._id || e.detail.projectId === props.project._id ) {
                            getRooms('silent')
                        }
                        break;
                    case 'new_equipment':
                    case 'equipment':
                    case 'rack':
                        if(e.detail.data?.projectId === props.project._id || e.detail.projectId === props.project._id) {
                            getRooms('silent')
                            getProjectEquipment('silent')
                        }
                        break;
                    case 'avTask':
                        getRoomAVOptions()
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            isWorking,
            isGettingEquipment,
            responseError,
            rooms,
            equipment,
            equipmentByManufacturer,
            kitsByManufacturer,
            //accessoriesByManufacturer,
            newRoomTypeOptions,
            newRoom,
            newRoomNumberOf,
            roomAvTaskOptions,
            showNewRoomDialog,
            toggleNewRoomDialog,

            showRoomNamesDialog,
            toggleRoomNamesDialog,
            renameRooms,
            nameUnnamedRooms,
            renameRoomsInProject,
            nameUnnamedRoomsInProject,

            saveNewRoom,
            filteredRooms,
            roomFilter,
            roomPhaseFilter,
            roomFloorFilter,
            roomFilterPhaseOptions,
            roomFilterFloorOptions,
            formatNumberInput,
            showReadiness,
            openDetails,
            showAddAVDetailsId,
            toggleNewRackDialog,
            showRacksInRoomId,
            //roomDays,
            updateRoom,
            dateInputToISO,
            isValid,
            parseISO,
            updateDate,
            formatISODateforInput,
            updateRoomReadiness,
            addAvTask,
            deleteRoom,
            removeAvTask,
            toggleAvTask,
            getColorCode,
            newRack,
            saveNewRack,
            updateRack,
            deleteRack,
            showEquipmentModal,
            equipmentFilter,
            isAllocated,
            roomAddOptions,
            selectedDestination,
            addToRoomFromSelect,
            addToRoomFromSelectMulti,
            removeEquipmentFromRoomOrRack,
            moveEquipment,
            showRoomContents,
            showRoomContentsObject,
            addToRoomDirect,
            XIcon,
            removeFromKit,
            expandAll,
            moveToRackFromRoom,
            moveToRoomFromRack,
            moveRackForward,
            handleRoomChangeMulti,
            handleRoomMultiInRoomButton,
            handleRoomMultiSingleRoomButton
        }
    }
}
</script>

<style>

</style>